<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal()"
      :closeOnBackdrop="false"
      class="modal-extended"
      size="xl"
    >

    <CRow class="mb-3 justify-content-center px-2">
        <CCol sm="12" lg="12">
          <div class="pr-2 d-flex justify-content-center" style="font-size: 8px;">
            <CCol  class="px-1 d-flex align-items-center justify-content-center">
              <CRow>
                <div class="d-flex align-items-center justify-content-center">
                  <span class="circle mr-2" style="background: #669bbc;"></span>
                </div>
              </CRow>
              <CRow>
                <CCol sm="12" class=" ml-2 d-flex justify-content-center">
                  <b>{{ $t('label.plannedQuantity') }}</b>
                </CCol>
              </CRow>
            </CCol>
            <CCol  class="px-1 d-flex align-items-center justify-content-center">
              <CRow>
                <div class="d-flex align-items-center justify-content-center">
                  <span class="circle mr-2" style="background: #e5e5e5;"></span>
                </div>
              </CRow>
              <CRow>
                <CCol sm="12" class=" ml-2 d-flex justify-content-center">
                  <b>{{ $t('label.identifiedQuantity') }}</b>
                </CCol>
              </CRow>
            </CCol>
            <CCol  class="px-1 d-flex align-items-center justify-content-center">
              <CRow>
                <div class="d-flex align-items-center justify-content-center">
                  <span class="circle mr-2" style="background: #42aa91;"></span>
                </div>
              </CRow>
              <CRow>
                <CCol sm="12" class=" ml-2 d-flex justify-content-center">
                  <b>{{ $t('label.dischargeQuantity') }}</b>
                </CCol>
              </CRow>
            </CCol>
            <CCol  class="px-1 d-flex align-items-center justify-content-center">
              <CRow>
                <div class="d-flex align-items-center justify-content-center">
                  <span class="circle mr-2" style="background: #FF8000;"></span>
                </div>
              </CRow>
              <CRow>
                <CCol sm="12" class=" ml-2 d-flex justify-content-center">
                  <b>{{ $t('label.pendingToShip') }}</b>
                </CCol>
              </CRow>
            </CCol>
            <CCol  class="px-1 d-flex align-items-center justify-content-center">
              <CRow>
                <div class="d-flex align-items-center justify-content-center">
                  <span class="circle mr-2 ml-2" style="background: #6A994E;"></span>
                </div>
              </CRow>
              <CRow>
                <CCol sm="12" class=" ml-3 d-flex justify-content-center">
                  <b>{{ $t('label.pendingToIndentify') }}/{{ $t('label.shipment') }}</b>
                </CCol>
              </CRow>
            </CCol>
            <CCol  class="px-1 d-flex align-items-center justify-content-center">
              <CRow>
                <div class="d-flex align-items-center justify-content-center">
                  <span class="circle mr-2" style="background: #fff3b0;"></span>
                </div>
              </CRow>
              <CRow>
                <CCol sm="12" class=" ml-2 d-flex justify-content-center">
                  <b>{{ $t('label.remainder') }}</b>
                </CCol>
              </CRow>
            </CCol>
          </div>
        </CCol>
      </CRow>

    <CTabs variant="tabs" :active-tab="0">
        <CTab :title="$t('label.packagingInformation')">
          <CCard class="card-border">
            <CRow class="mt-3 justify-content-center px-2">
              <CCol sm="12" lg="10" v-if="!isEdit">
                <CSelect
                  :horizontal="{label:'required text-right col-sm-12 col-lg-3', input:'col-sm-12 col-lg-6'}"
                  :label="$t('label.packaging')"
                  :options="packagingOptions"
                  v-model.trim="$v.PackagingId.$model"
                  :value.sync="PackagingId"
                  :is-valid="hasError($v.PackagingId)" 
                  :invalid-feedback="errorMessage($v.PackagingId)"
                  size="sm"
                  @blur="$v.PackagingId.$touch()"
                  @change="filterPackaging($event)"
                />      
              </CCol>
              <CCol sm="12" lg="10" v-if="isEdit">
                <CInput
                  :horizontal="{label:'required text-right col-sm-12 col-lg-3', input:'col-sm-12 col-lg-6'}"
                  :label="$t('label.packaging')"
                  v-model.trim="$v.PackagingName.$model"
                  :value.sync="PackagingName"
                  :is-valid="hasError($v.PackagingName)" 
                  :invalid-feedback="errorMessage($v.PackagingName)"
                  disabled
                  size="sm"
                />      
              </CCol>
            </CRow>
            <CRow class="mb-3 justify-content-center px-2">
              <CCol sm="12" lg="12">
                <div class="pr-2 d-flex justify-content-center" style="font-size: 10px;">
                  <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
                    <div class="block-blue text-center my-2">
                      <h3 class="text-black my-2">{{ NumberFormated(this.QuantityPlanningPackaging ?? 0) }}</h3>
                    </div>
                  </CCol>
                  <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
                    <div class="block-grey text-center my-2">
                      <h3 class="text-black my-2">{{ NumberFormated(this.QuantityIdentifyPackaging ?? 0) }}</h3>
                    </div>
                  </CCol>
                  <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
                    <div class="block-green text-center my-2">
                      <h3 class="text-black my-2">{{ NumberFormated(this.QuantityStowage ?? 0) }}</h3>
                    </div>
                  </CCol>
                  <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
                    <div class="block-orange text-center my-2">
                      <h3 class="text-black my-2">{{ NumberFormated(this.RemainPackaging ?? 0) }}</h3>
                    </div>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CCard>
        </CTab>
      </CTabs>

      <CTabs variant="tabs" :active-tab="0" >
        <CTab :title="$t('label.blInformation')">
          <CCard class="card-border">
            <CRow class="mt-3 px-2">
              <CCol sm="12" lg="6" v-if="!isEdit">
                <CSelect
                  :horizontal="{label:'required text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                  :label="$t('label.bl')"
                  :options="blOptions"
                  v-model.trim="$v.VisitBillOfLadingPackagingId.$model"
                  :value.sync="VisitBillOfLadingPackagingId"
                  :is-valid="hasError($v.VisitBillOfLadingPackagingId)" 
                  :invalid-feedback="errorMessage($v.VisitBillOfLadingPackagingId)"
                  size="sm"
                  :disabled="PackagingId ? false : true"
                  @blur="$v.VisitBillOfLadingPackagingId.$touch()"
                  @change="filterBl($event)"
                />     
              </CCol>
              <CCol sm="12" lg="6" v-if="isEdit">
                  <CInput
                    :horizontal="{label:'required text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                    :label="$t('label.bl')"
                    v-model.trim="$v.NroBl.$model"
                    :value.sync="NroBl"
                    :is-valid="hasError($v.NroBl)" 
                    :invalid-feedback="errorMessage($v.NroBl)"
                    disabled
                    size="sm"
                  />      
                </CCol>
              <CCol sm="12" lg="6">
                <CInput
                  :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                  :label="$t('label.exporter')"
                  :placeholder="$t('label.exporter')"
                  v-model.trim="$v.Consignee.$model"
                  :value.sync="Consignee"
                  :is-valid="hasError($v.Consignee)" 
                  :invalid-feedback="errorMessage($v.Consignee)"
                  size="sm"
                  @blur="$v.Consignee.$touch()"
                  disabled
                />     
              </CCol>

            </CRow>
            <CRow class="justify-content-center px-2">
              <CCol sm="12" lg="6">
                <CInput
                  :horizontal="{label:'text-right col-sm-12 col-lg-5', input:'col-sm-12 col-lg-6'}"
                  :label="$t('label.commodity')"
                  :placeholder="$t('label.commodity')"
                  v-model.trim="$v.CommodityName.$model"
                  :value.sync="CommodityName"
                  :is-valid="hasError($v.CommodityName)" 
                  :invalid-feedback="errorMessage($v.CommodityName)"
                  size="sm"
                  @blur="$v.CommodityName.$touch()"
                  disabled
                />        
              </CCol>
              <CCol sm="12" lg="6">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{$t('label.remainder')}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!$v.SmallerRemain.$dirty ? 'form-control' : ($v.SmallerRemain.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="SmallerRemain"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                    <div class="invalid-feedback" v-if="$v.SmallerRemain.$error">
                      {{ errorMessage($v.SmallerRemain) }}
                    </div>
                  </div>
                </div>     
              </CCol>
            </CRow> 
            <CRow class="justify-content-center px-2">
              <CCol sm="12" lg="6">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 col-form-label-sm required">{{$t('label.quantity')}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!$v.Quantity.$dirty ? 'form-control' : ($v.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="Quantity"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="$v.Quantity.$error">
                      {{ errorMessage($v.Quantity) }}
                    </div>
                  </div>
                </div>     
              </CCol>
              <CCol sm="12" lg="6" v-if="isEdit">
                <CSelect
                  :label="$t('label.status')"
                  addLabelClasses="text-right required"
                  :horizontal="{label:'col-sm-12 col-lg-5 col-xl-5',input:'col-sm-12 col-lg-6'}"
                  :value="Status"
                  :is-valid="statusSelectColor"
                  v-model.trim="Status"
                  :options="statusOptions"
                  size="sm"
                  />
              </CCol>
              <CCol sm="12" lg="6" v-if="!isEdit">
              </CCol>
            </CRow>
            <CRow class="mb-3 justify-content-center px-2">
            <CCol sm="12" lg="12">
              <div class="pr-2 d-flex justify-content-center" style="font-size: 10px;">
                <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
                  <div class="block-blue text-center my-2">
                    <h3 class="text-black my-2">{{ NumberFormated(this.QuantityPlanning ?? 0) }}</h3>
                  </div>
                </CCol>
                <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
                  <div class="block-grey text-center my-2">
                    <h3 class="text-black my-2">{{ NumberFormated(this.QuantityIdentify ?? 0) }}</h3>
                  </div>
                </CCol>
                <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
                  <div class="block-green-two text-center my-2">
                    <h3 class="text-black my-2">{{ NumberFormated(this.Remain ?? 0) }}</h3>
                  </div>
                </CCol>
                <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
                  <div class="block-yellow text-center my-2">
                    <h3 class="text-black my-2">{{ NumberFormated(this.SmallerRemain ?? 0) }}</h3>
                  </div>
                </CCol>
              </div>
            </CCol>
          </CRow>

          </CCard>
        </CTab>
      </CTabs>
      <template #footer>
        <CButton outline color="add" :disabled="isSubmit"
          @click.stop="isEdit ? statusConfirmation(assignmentItem.FgActVisitBlIdentification,Status, submit) : submit()"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal"  :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
    </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import mixinGeneral from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import { mapState } from 'vuex';
import assignmentValidation from '@/_validations/visitas/general-cargo/assignmentValidation';
import { Money } from "v-money";
import { NumberFormater} from '@/_helpers/funciones';

function data() {
  return {
    VisitBillOfLadingPackagingId: '',
    VisitBlPackagingIdentificationId: '',
    Consignee: '',
    CommodityName: '',
    PackagingId: '',
    PackagingName: '',
    NroBl: '',
    QuantityPlanning: 0,
    QuantityIdentify: 0,
    QuantityPlanningPackaging: 0,
    QuantityIdentifyPackaging: 0,
    QuantityStowage: 0,
    RemainPackaging: 0,
    Quantity: 0,
    Remain: 0,
    Status: 0,
    SmallerRemain: 0,
   
    showModal: false,
    loadingOverlay: false,
    isSubmit: false,
    PackagingList: [],
    BlList: [],
   
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
  };
}

async function getPackagingList() {
  this.loadingOverlay = true;
  await this.$http
      .get('VisitGeneralCargoExportPackagingResum-by-VisitId?VisitId='+this.Visit)
          .then(async response => {
          this.PackagingList = response.data.data ?? [];
        })
        .catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      }).finally(() => {
        this.loadingOverlay = false;
      });
}

async function filterPackaging(event) {
  this.PackagingId=event.target.value;
  this.VisitBillOfLadingPackagingId = '';
  this.BlList = [];
  this.QuantityPlanningPackaging = 0;
  this.QuantityIdentifyPackaging = 0;
  this.QuantityStowage =  0;
  this.RemainPackaging =  0;
  this.Consignee = '';
  this.CommodityName = '';
  this.QuantityPlanning = 0; 
  this.QuantityIdentify = 0;
  this.Quantity = 0;
  this.Remain = 0;
  this.SmallerRemain = 0;
  if (this.PackagingId) {
    await this.getBLList();
    await this.getQuantityData(this.PackagingId);
  }else{
    this.VisitBillOfLadingPackagingId = '';
    this.BlList = [];
    this.QuantityPlanningPackaging = 0;
    this.QuantityIdentifyPackaging = 0;
    this.QuantityStowage =  0;
    this.RemainPackaging =  0;
    this.Consignee = '';
    this.CommodityName = '';
    this.QuantityPlanning = 0; 
    this.QuantityIdentify = 0;
    this.Quantity = 0;
    this.Remain = 0;
    this.SmallerRemain = 0;
  }
}

async function getQuantityData(Id) {
  if(this.PackagingId){
    let data = this.PackagingList.find((val) => val.PackagingId == Id);
    this.QuantityPlanningPackaging = data?.QuantityPlanning ?? 0;
    this.QuantityIdentifyPackaging = data?.QuantityIdentified ?? 0;
    this.QuantityStowage = data?.QuantityStowage ?? 0;
    this.RemainPackaging = data?.Remain ?? 0;
  }else{
    this.VisitBillOfLadingPackagingId = '';
    this.BlList = [];
    this.QuantityPlanningPackaging = 0;
    this.QuantityIdentifyPackaging = 0;
    this.QuantityStowage =  0;
    this.RemainPackaging =  0;
    this.Consignee = '';
    this.CommodityName = '';
    this.QuantityPlanning = 0; 
    this.QuantityIdentify = 0;
    this.Quantity = 0;
    this.Remain = 0;
    this.SmallerRemain = 0;
  }
}

async function filterBl(event) {
    event ? this.VisitBillOfLadingPackagingId = event.target.value : ''; 
    if(this.VisitBillOfLadingPackagingId){
      let data = this.BlList.find((val) => val.VisitBillOfLadingPackagingId == this.VisitBillOfLadingPackagingId);
      let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
      this.SmallerRemain = data?.Remain > 0 ? ( Number(data?.Remain < this.RemainPackaging ) ? data?.Remain : this.RemainPackaging) : this.RemainPackaging;
      this.Consignee = data?.ClientName ?? '',
      this.NroBl = data?.NroBl ?? '',
      this.CommodityName = data?.[`CommodityName${_lang}`] ?? '';
      this.QuantityPlanning = data?.QuantityPlanning ?? 0;
      this.QuantityIdentify = data?.QuantityIdentify ?? 0;
      this.Remain = data?.Remain ?? 0;
      
    }else{
      this.Consignee = '';
      this.CommodityName = '';
      this.QuantityPlanning = 0; 
      this.QuantityIdentify = 0;
      this.Quantity = 0;
      this.Remain = 0;
    }
  }

async function getBLList () {
  this.loadingOverlay = true;
  let list = [];
  let VisitJson = [{
      VisitId: this.Visit,
      PackagingId: this.PackagingId
  }];
  await this.$http.post("VisitBillOfLadingExportPackaging-list", VisitJson, { root: 'VisitJson' })
  .then(response => {
      list = response.data.data ?? [];
      this.BlList = Object.keys(list[0]).length>1 ? list : [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
      this.loadingOverlay = false;
  });
}

async function submit(){
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error || (this.Quantity > this.SmallerRemain)) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      
      if (this.Quantity > this.SmallerRemain) {
        throw this.$t('label.quantityRemainErrorBl');
      }

      throw this.$t('label.errorsPleaseCheck');
    }

    let VisitJson = this.isEdit ? {
      VisitBlPackagingIdentificationId: this.VisitBlPackagingIdentificationId,
      VisitBillOfLadingPackagingId: this.VisitBillOfLadingPackagingId,
      Quantity: this.Quantity,
      Status: this.Status
    } : {
      VisitBillOfLadingPackagingId: this.VisitBillOfLadingPackagingId,
      Quantity: this.Quantity
    }

    let ruta =  this.isEdit ? 'VisitBlExportPackagingIdentification-update' : 'VisitBlExportPackagingIdentification-insert';
    let method = this.isEdit ? 'PUT':'POST';


    await this.$http
      .ejecutar (method, ruta, VisitJson, { root: 'VisitJson' })
      .then((response) => {
        if (response.status === (200 || 201)) {
          this.closeModal();
          this.$emit('child-refresh');
          this.loadingOverlay = false;
          this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Exito!',
            text: response.data.data[0].Response,
            type: "success"
          });
        }
      })
      .catch((e) => {
      this.loadingOverlay = false;
      this.isSubmit = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: "error"
        });
      }).then(() => {
      this.loadingOverlay = false;
      this.isSubmit = false;
    });
  } catch (e) {
    this.loadingOverlay = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

async function refreshComponent() {
    this.Consignee = '';
    this.CommodityName = '';
    this.PackagingId = '';
    this.PackagingName = '';
    this.NroBl = '';
    this.QuantityPlanning = 0;
    this.QuantityIdentify = 0;
    this.Quantity = 0;
    this.Remain = 0;
    this.SmallerRemain = 0;
    this.QuantityPlanningPackaging = 0;
    this.QuantityIdentifyPackaging = 0;
    this.QuantityStowage =  0;
    this.RemainPackaging = 0;
    this.Status = 0;
    this.isSubmit = false;
    this.PackagingList = [];
    this.VisitBillOfLadingPackagingId = '';
    this.VisitBlPackagingIdentificationId = '';
    this.BlList = [];

    this.$v.$reset();
}

function closeModal() {
  this.refreshComponent();
  this.$emit('closeModal');
}

function statusSelectColor() {
  return this.Status === 1;
}

async function getData(val) {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  this.Consignee = val?.ClientName ?? '';
  this.CommodityName = val[`CommodityName${_lang}`] ?? '';
  this.PackagingId = val?.PackagingId ?? '';
  this.PackagingName = val[`PackagingName${_lang}`];
  this.NroBl = val?.NroBl ?? '';
  this.QuantityPlanning = val?.QuantityPlanning ?? 0;
  this.Remain = val?.Remain ?? 0;
  this.Status = val?.FgActVisitBlIdentification ? 1 : 0;
  this.VisitBillOfLadingPackagingId = val?.VisitBillOfLadingPackagingId ?? '';
  this.VisitBlPackagingIdentificationId = val?.VisitBlPackagingIdentificationId ?? '';
  await this.getBLList();
  await this.filterBl();
  await this.getQuantityData(this.PackagingId);
  let data = this.BlList.find((e) => e.VisitBillOfLadingPackagingId == this.VisitBillOfLadingPackagingId);
  let aux = data?.Remain > 0 ? ( Number(data?.Remain < this.RemainPackaging ) ? data?.Remain : this.RemainPackaging) : this.RemainPackaging;

  this.SmallerRemain = (NumberFormater.setNum(aux)) + (NumberFormater.setNum(val?.Quantity))
  this.Quantity = val?.Quantity ? NumberFormater.setNum(val?.Quantity) : 0;


  this.$v.$touch();
}

function NumberFormated(value){
  return value ? NumberFormater.formatNumber(value, 2) : '0,00';
}

//computed
function packagingOptions(){
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PackagingList.map((e) => {
      chart.push({
        value: e.PackagingId, 
        label: e[`PackagingName${_lang}`]
      });
  });
  return chart;
}

function blOptions(){
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.BlList.map((e) => {
      chart.push({
        value: e.VisitBillOfLadingPackagingId, 
        label: e.NroBl
      });
  });
  return chart;
}

function titleModal() {
  return  this.isEdit ? this.$t('label.edit')+' '+this.$t('label.assignExporter') :
          this.$t('label.nueva')+' '+this.$t('label.assignExporter');
}


export default {
  name: 'modal-assignment',
  data,
  props: { modal: Boolean, assignmentItem: Object, isEdit: Boolean },
  mixins: [ModalMixin, mixinGeneral, mixinServicio],
  components: { Money },
  directives: UpperCase,
  validations(){
    return assignmentValidation(this.Valid, NumberFormater.setNum(this.SmallerRemain));
  },

  watch: {
    modal: async function (val) {
      this.showModal = val;
      if(val){
        await this.getPackagingList();
        if (this.isEdit) {
          await this.getData(this.assignmentItem);
        }
      
      }
    },
    Quantity: function (val) {
      try {
        if (NumberFormater.setNum(val) > 0) {
          if (NumberFormater.setNum(val) > NumberFormater.setNum(this.SmallerRemain)) {
            throw this.$t('label.quantityRemainErrorBl');
          }
        }
      } catch (error) {
          this.notifyError({text: error});
      }
    },
  },
  methods: {
    closeModal,
    statusSelectColor,
    refreshComponent,
    filterPackaging,
    filterBl,
    getQuantityData,
    getBLList,
    submit,
    getPackagingList,
    getData,
    NumberFormated
  },
  computed: {
    packagingOptions,
    blOptions,
    titleModal,
    ...mapState({
      Visit: state => state.visitas.VisitId,      
    }),
  },
};
</script>
<style lang="scss">
.status-open {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #42aa91 !important;
  }

}
.status-close {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #FF8000 !important;
  }
  .form-control:focus {
    border-color: #FF8000 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #FF8000 !important;
    box-shadow: 0 0 0 0.1rem #FF8000 !important;
  }
}
.status-inactive {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #e1373f !important;
  }
  .form-control:focus {
    border-color: #e1373f !important;
    -webkit-box-shadow: 0 0 0 0.1rem #e1373f !important;
    box-shadow: 0 0 0 0.1rem #e1373f !important;
  }
}
.status-EndOperation {
  .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #1A3760 !important;
  }
  .form-control:focus {
    border-color: #1A3760 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #1A3760 !important;
    box-shadow: 0 0 0 0.1rem #1A3760 !important;
  }
}
</style>
